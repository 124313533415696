
import { defineComponent, onMounted, ref, watch } from 'vue';
import useWallet from '../../composables/wallet';
import useCluster from '../../composables/cluster';
import { initGemFarm } from '../../common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import { BN } from '@project-serum/anchor';

export default defineComponent({
  props: {
    farm: String,
  },
  emits: ['update-farm'],
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    let gf: any;
    watch([wallet, cluster], async () => {
      gf = await initGemFarm(getConnection(), getWallet()!);
    });

    //needed coz mounts later
    onMounted(async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
      }
    });

    // --------------------------------------- update farm
    const manager = ref<string>();
    const minStakingPeriodSec = ref<string>();
    const cooldownPeriodSec = ref<string>();
    const unstakingFeeLamp = ref<string>();

    const updateFarm = async () => {
      let newConfig;
      if (
        minStakingPeriodSec.value ||
        cooldownPeriodSec.value ||
        unstakingFeeLamp.value
      ) {
        newConfig = {
          minStakingPeriodSec: new BN(minStakingPeriodSec.value!),
          cooldownPeriodSec: new BN(cooldownPeriodSec.value!),
          unstakingFeeLamp: new BN(unstakingFeeLamp.value!),
        };
      }

      await gf.updateFarmWallet(
        new PublicKey(props.farm!),
        newConfig,
        manager.value ? new PublicKey(manager.value) : undefined
      );
      ctx.emit('update-farm');
    };

    return {
      manager,
      minStakingPeriodSec,
      cooldownPeriodSec,
      unstakingFeeLamp,
      updateFarm,
    };
  },
});
