import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7d54a48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-black flex flex-row justify-center align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavButton = _resolveComponent("NavButton")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (i) => {
      return (_openBlock(), _createBlock(_component_NavButton, {
        key: i.name,
        title: i.name,
        url: i.url,
        class: _normalizeClass({ active: _ctx.path === i.url })
      }, null, 8, ["title", "url", "class"]))
    }), 128))
  ]))
}