
import { defineComponent, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';

export default defineComponent({
  props: {
    farm: String,
  },
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    let gf: any;
    watch([wallet, cluster], async () => {
      gf = await initGemFarm(getConnection(), getWallet()!);
    });

    //need an onmounted hook because this component isn't yet mounted when wallet/cluster are set
    onMounted(async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
      }
      await getCurrentFunders(props.farm!);
    });

    // --------------------------------------- funders
    const toAuthorize = ref<string>();
    const toDeauthorize = ref<string>();
    const funders = ref<any[]>();

    const getCurrentFunders = async (farm: string) => {
      funders.value = await gf!.fetchAllAuthProofPDAs(new PublicKey(farm));
    };

    const authorizeFunder = async () => {
      await gf!.authorizeFunderWallet(
        new PublicKey(props.farm!),
        new PublicKey(toAuthorize.value!)
      );
      await getCurrentFunders(props.farm!);
    };

    const deauthorizeFunder = async () => {
      await gf!.deauthorizeFunderWallet(
        new PublicKey(props.farm!),
        new PublicKey(toDeauthorize.value!)
      );
      await getCurrentFunders(props.farm!);
    };

    return {
      funders,
      toAuthorize,
      toDeauthorize,
      authorizeFunder,
      deauthorizeFunder,
    };
  },
});
