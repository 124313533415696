import { PublicKey } from '@solana/web3.js';

export const DEFAULTS = {
  CLUSTER: 'mainnet',
  //todo these need to be PER cluster
  GEM_BANK_PROG_ID: new PublicKey(
    'DiNoGpgNUyUok7WYUm5Q6FujZPRnNkPS9iVGmhkLXM38'
  ),
  GEM_FARM_PROG_ID: new PublicKey(
    'DiGioibNn8m9s6KFszgVkUPodhbK33WhuVzWtLeRNFf'
  ),
};
