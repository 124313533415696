import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Gen1Farmer from '@/views/Gen1Farmer.vue';
import manager from '@/views/Manager.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Staking',
    component: Gen1Farmer,
  },
  {
    path: '/manager',
    name: 'Manager',
    component: manager,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
