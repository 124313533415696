<template>
  <TheNavBar />
  <router-view />
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
export default {
  components: { TheNavBar },
};

</script>

<style>
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
