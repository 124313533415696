
import { defineComponent, ref } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { BrowserWallet } from '../../../tests/gem-common/browser-wallet';

export default defineComponent({
  setup() {
    const { getWallet } = useWallet();
    const { getConnection } = useCluster();

    const mint = ref<string>();

    const createTestReward = async () => {
      const bw = new BrowserWallet(getConnection(), getWallet() as any);

      const { mint: rewardMint } = await bw.createMintAndFundATA(0, 1000000);
      mint.value = rewardMint.toBase58();
    };

    return {
      mint,
      createTestReward,
    };
  },
});
