
import { defineComponent, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';

export default defineComponent({
  props: {
    farm: String,
    farmAcc: Object,
  },
  emits: ['update-farm'],
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    let gf: any;
    watch([wallet, cluster], async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
      }
    });

    //need an onmounted hook because this component isn't yet mounted when wallet/cluster are set
    onMounted(async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
        setRewardType(selectedReward.value);
      }
    });

    // --------------------------------------- fund / cancel / lock
    const selectedReward = ref<string>('rewardA');
    const activeRewardType = ref<string>();

    //fixed reward
    const baseRate = ref<string>();
    const denominator = ref<string>();
    const t1RewardRate = ref<string>();
    const t1RequiredTenure = ref<string>();
    const t2RewardRate = ref<string>();
    const t2RequiredTenure = ref<string>();
    const t3RewardRate = ref<string>();
    const t3RequiredTenure = ref<string>();

    const amount = ref<string>();
    const duration = ref<string>();

    const setRewardType = (selectedReward: string) => {
      activeRewardType.value = gf.parseRewardType(
        props.farmAcc![selectedReward]
      );
    };

    watch(selectedReward, (newReward: string) => {
      setRewardType(newReward);
    });

    const fundReward = async () => {
      if (activeRewardType.value === 'variable') {
        await gf.fundVariableRewardWallet(
          new PublicKey(props.farm!),
          new PublicKey(props.farmAcc![selectedReward.value].rewardMint),
          amount.value,
          duration.value
        );
      } else {
        await gf.fundFixedRewardWallet(
          new PublicKey(props.farm!),
          new PublicKey(props.farmAcc![selectedReward.value].rewardMint),
          amount.value,
          duration.value,
          baseRate.value,
          denominator.value,
          t1RewardRate.value,
          t1RequiredTenure.value,
          t2RewardRate.value,
          t2RequiredTenure.value,
          t3RewardRate.value,
          t3RequiredTenure.value
        );
      }
      ctx.emit('update-farm');
    };

    const cancelReward = async () => {
      await gf.cancelRewardWallet(
        new PublicKey(props.farm!),
        new PublicKey(props.farmAcc![selectedReward.value].rewardMint)
      );
      ctx.emit('update-farm');
    };

    const lockReward = async () => {
      await gf.lockRewardWallet(
        new PublicKey(props.farm!),
        new PublicKey(props.farmAcc![selectedReward.value].rewardMint)
      );
      ctx.emit('update-farm');
    };

    return {
      selectedReward,
      activeRewardType,
      baseRate,
      denominator,
      t1RewardRate,
      t1RequiredTenure,
      t2RewardRate,
      t2RequiredTenure,
      t3RewardRate,
      t3RequiredTenure,
      amount,
      duration,
      fundReward,
      cancelReward,
      lockReward,
    };
  },
});
