
import { defineComponent } from 'vue';
import RewardDisplay from '@/components/gem-farm/RewardDisplay.vue';
export default defineComponent({
  components: { RewardDisplay },
  props: {
    farmAcc: Object,
  },
  setup() {
    return {};
  },
});
